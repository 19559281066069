var app = new Vue({
   el: '.js-navigation',
   data() {
      return {
         navigationOpen: false,
         active: [],
      }
   },
   methods: {
      toggleNavigation() {
         this.navigationOpen = !this.navigationOpen
      },
      toggleActive(id) {
         const index = this.active.indexOf(id)
         if (index > -1) {
            this.active.splice(index, 1)
         } else {
            this.active.push(id)
         }
      },
      isActive(id) {
         return this.active.includes(id)
      },
   },
})
