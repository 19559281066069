var app = new Vue({
   el: '.js-contact-form',
   data() {
      return {
         loading: false,
         send: false,
         name: null,
         message: null,
         email: null,
         toEmail: null,
      }
   },
   methods: {
      submit() {
         this.loading = true

         var messageData = {
            Name: this.name,
            Email: this.email,
            ToEmail: this.toEmail,
            Message: this.message,
         }

         fetch(window.location.origin + '/Umbraco/Api/Contact/SendMessage', {
            method: 'post',
            body: JSON.stringify(messageData),
            headers: {
               'Content-Type': 'application/json',
            },
         }).then(() => {
            this.send = true
            this.loading = false
         })
      },
   },
})
