var app = new Vue({
   el: '.js-appartment-view',
   data() {
      return {
         blueprintsOpen: false,
      }
   },
   mounted() {},
   methods: {
      toggleBlueprints() {
         this.blueprintsOpen = !this.blueprintsOpen
      },
   },
})
