var app = new Vue({
   el: '.js-appartment-finder',
   data() {
      return {
         appartments: [],
         loading: false,
         params: {
            priceFrom: '',
            priceTo: '',
            areaFrom: '',
            areaTo: '',
            roomsFrom: '',
            roomsTo: '',
         },
      }
   },
   mounted() {
      this.search()
   },
   methods: {
      submitHandler() {
         this.search()
      },
      search() {
         this.loading = true

         var url = new URL(
            window.location.origin + '/Umbraco/Api/Appartments/GetAppartments'
         )

         url.search = new URLSearchParams({ ...this.params }).toString()

         fetch(url, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
            .then((response) => response.json())
            .then((data) => {
               this.appartments = data
               this.loading = false
               this.loadMap()
            })
      },
      loadMap() {
         if (!document.getElementById('map')) {
            return
         }

         var styles = this.getMapStyles()
         var styledMap = new google.maps.StyledMapType(styles, {
            name: 'Styled Map',
         })

         var mapOptions = {
            disableDefaultUI: true,
            mapTypeControlOptions: {
               mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style'],
            },
         }
         var map = new google.maps.Map(
            document.getElementById('map'),
            mapOptions
         )
         var latlngbounds = new google.maps.LatLngBounds()

         this.appartments.map((x) => {
            var latLng = new google.maps.LatLng(
               parseFloat(x.Latitude),
               parseFloat(x.Longitude)
            )

            latlngbounds.extend(latLng)

            var marker = new google.maps.Marker({
               position: latLng,
               map: map,
               title: x.Name,
               icon: '/dist/img/map-pin.png',
               animation: google.maps.Animation.DROP,
            })

            google.maps.event.addListener(marker, 'click', function () {
               window.location.href = window.location.origin + x.Url
            })
         })

         map.fitBounds(latlngbounds)

         map.mapTypes.set('map_style', styledMap)
         map.setMapTypeId('map_style')
      },
      getMapStyles() {
         return [
            {
               featureType: 'administrative',
               elementType: 'geometry.stroke',
               stylers: [{ visibility: 'on' }, { color: '#ababab' }],
            },
            {
               featureType: 'administrative',
               elementType: 'labels.text.fill',
               stylers: [{ color: '#444444' }],
            },
            {
               featureType: 'administrative.country',
               elementType: 'geometry.stroke',
               stylers: [{ lightness: '59' }],
            },
            {
               featureType: 'administrative.country',
               elementType: 'labels.text',
               stylers: [
                  { lightness: '50' },
                  { color: '#a5a5a5' },
                  { weight: '0.21' },
               ],
            },
            {
               featureType: 'administrative.province',
               elementType: 'geometry.stroke',
               stylers: [
                  { weight: '0.5' },
                  { color: '#afafaf' },
                  { visibility: 'on' },
               ],
            },
            {
               featureType: 'administrative.province',
               elementType: 'labels.text',
               stylers: [{ visibility: 'off' }],
            },
            {
               featureType: 'administrative.locality',
               elementType: 'labels.text',
               stylers: [{ visibility: 'on' }, { lightness: '59' }],
            },
            {
               featureType: 'administrative.locality',
               elementType: 'labels.text.fill',
               stylers: [{ lightness: '-16' }],
            },
            {
               featureType: 'administrative.locality',
               elementType: 'labels.icon',
               stylers: [{ lightness: '48' }, { visibility: 'on' }],
            },
            {
               featureType: 'administrative.neighborhood',
               elementType: 'labels.text',
               stylers: [{ visibility: 'off' }],
            },
            {
               featureType: 'administrative.land_parcel',
               elementType: 'geometry.stroke',
               stylers: [{ visibility: 'off' }, { color: '#c4c4c7' }],
            },
            {
               featureType: 'administrative.land_parcel',
               elementType: 'labels.text',
               stylers: [{ visibility: 'off' }],
            },
            {
               featureType: 'landscape',
               elementType: 'all',
               stylers: [{ color: '#f2f2f2' }],
            },
            {
               featureType: 'poi',
               elementType: 'all',
               stylers: [{ visibility: 'off' }],
            },
            {
               featureType: 'road',
               elementType: 'all',
               stylers: [{ saturation: -100 }, { lightness: 45 }],
            },
            {
               featureType: 'road.highway',
               elementType: 'all',
               stylers: [{ visibility: 'on' }],
            },
            {
               featureType: 'road.highway',
               elementType: 'geometry.fill',
               stylers: [{ color: '#eaeaea' }, { weight: '.5' }],
            },
            {
               featureType: 'road.highway',
               elementType: 'geometry.stroke',
               stylers: [{ weight: '.75' }, { color: '#e4e3e3' }],
            },
            {
               featureType: 'road.highway',
               elementType: 'labels',
               stylers: [{ visibility: 'on' }, { lightness: '20' }],
            },
            {
               featureType: 'road.highway',
               elementType: 'labels.text.fill',
               stylers: [{ visibility: 'on' }],
            },
            {
               featureType: 'road.highway',
               elementType: 'labels.text.stroke',
               stylers: [{ weight: '0.49' }],
            },
            {
               featureType: 'road.arterial',
               elementType: 'all',
               stylers: [{ visibility: 'on' }],
            },
            {
               featureType: 'road.arterial',
               elementType: 'labels.text',
               stylers: [{ visibility: 'on' }],
            },
            {
               featureType: 'road.arterial',
               elementType: 'labels.text.fill',
               stylers: [{ lightness: '43' }],
            },
            {
               featureType: 'road.arterial',
               elementType: 'labels.icon',
               stylers: [{ visibility: 'off' }],
            },
            {
               featureType: 'road.local',
               elementType: 'all',
               stylers: [{ visibility: 'on' }],
            },
            {
               featureType: 'road.local',
               elementType: 'geometry.stroke',
               stylers: [
                  { visibility: 'on' },
                  { saturation: '-41' },
                  { color: '#c8c8c8' },
               ],
            },
            {
               featureType: 'transit',
               elementType: 'all',
               stylers: [{ visibility: 'off' }],
            },
            {
               featureType: 'water',
               elementType: 'all',
               stylers: [{ color: '#ffffff' }, { visibility: 'on' }],
            },
            {
               featureType: 'water',
               elementType: 'geometry.fill',
               stylers: [{ visibility: 'on' }, { color: '#e1e1e1' }],
            },
            {
               featureType: 'water',
               elementType: 'labels.text',
               stylers: [
                  { lightness: '100' },
                  { gamma: '1.13' },
                  { saturation: '-96' },
                  { visibility: 'on' },
                  { color: '#a5a5a5' },
                  { weight: '0.59' },
               ],
            },
         ]
      },
   },
})
