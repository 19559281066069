var app = new Vue({
   el: '.js-waiting-list',
   data() {
      return {
         selected: [],
         rooms: [],
         name: null,
         email: null,
         phone: null,
         movein: 'now',
         message: null,
         loading: false,
         send: false,
         toEmail: null,
      }
   },
   mounted() {
      var url = new URL(window.location.href)
      var area = url.searchParams.get('area')

      if (area) {
         this.selected.push(area)
      }
   },
   methods: {
      submit() {
         this.loading = true

         var messageData = {
            Rooms: this.rooms,
            Selected: this.selected,
            MoveIn: this.movein,
            Name: this.name,
            Email: this.email,
            Phone: this.phone,
            ToEmail: this.toEmail,
            Message: this.message,
         }

         fetch(window.location.origin + '/Umbraco/Api/WaitingList/Add', {
            method: 'post',
            body: JSON.stringify(messageData),
            headers: {
               'Content-Type': 'application/json',
            },
         }).then(() => {
            this.send = true
            this.loading = false
         })
      },
   },
})
